<template>
	<div class="card card-bordered" :data-expired="token">
		<div class="card-header text-center" v-if="status == 'loading'">
			<div class="spinner spinner-grow"></div>
			<h6 class="card-title text-center">Processing...</h6>
		</div>

		<div class="card-header text-center" v-if="token">
			<div class="alert alert-danger">
				You have been logged out due to inactivity.
			</div>
		</div>

		<div class="card-body">
			<div
				class="alert alert-danger text-danger mb-2"
				v-if="has_error && !success"
			>
				<p>{{ error }}.</p>
			</div>

			<form
				autocomplete="off"
				@submit.prevent="registerUser"
				method="post"
				v-if="registering"
			>
				<div class="form-group">
					<label for="first_name">
						Your First Name
					</label>
					<input
						type="tel"
						id="first_name"
						class="form-control"
						placeholder="Enter first name"
						v-model="user.first_name"
						required
					/>
				</div>

				<div class="form-group">
					<label for="last_name">
						Your Last Name
					</label>
					<input
						type="tel"
						id="last_name"
						class="form-control"
						placeholder="Enter last name"
						v-model="user.last_name"
						required
					/>
				</div>
				<div class="form-group">
					<label for="email">
						Your Email Address
					</label>
					<input
						type="tel"
						id="email"
						class="form-control"
						placeholder="Enter email"
						v-model="user.email"
						autocomplete="email"
						required
					/>
				</div>
				<div class="form-group">
					<label for="phone">
						Your Phone Number
					</label>
					<input
						type="tel"
						id="phone"
						class="form-control"
						placeholder="Enter phone"
						v-model="user.phone"
						autocomplete="username"
						required
					/>
				</div>

				<div class="form-group">
					<label for="password">
						Account Password
					</label>
					<input
						type="tel"
						id="password"
						class="form-control"
						placeholder="Enter password"
						v-model="user.password"
						required
					/>
				</div>
				<div class="form-group">
					<button type="submit" class="btn btn-primary float-right">
						<span>Create Account</span>
					</button>
					<div class="text-center mt-4 font-weight-light hidden">
						<button
							@click.prevent="showLoginForm"
							class="link text-primary"
						>
							Login Instead
						</button>
					</div>
				</div>
			</form>

			<form
				autocomplete="off"
				@submit.prevent="loginUser"
				method="post"
				v-else
			>
				<div class="form-group">
					<label for="username">
						Phone Number or Email Address
					</label>
					<input
						type="text"
						id="username"
						class="form-control"
						placeholder="Enter phone or email"
						v-model="user.username"
						autocomplete="username"
						required
					/>
				</div>
				<div class="form-group">
					<label for="password">
						Account Password
					</label>
					<input
						type="password"
						id="password"
						class="form-control"
						v-model="user.password"
						required
						placeholder="Enter password"
						autocomplete="current-password"
					/>
				</div>

				<div class="form-group">
					<button type="submit" class="btn btn-primary float-right">
						Sign In
					</button>
				</div>

				<div class="text-center mt-4 font-weight-light hidden">
					No Account?
					<button
						@click.prevent="showRegisterForm"
						class="link text-primary"
					>
						Register Instead
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script lang="ts">
	import {
		defineComponent,
		computed,
		ref,
		reactive,
		onMounted,
		onUnmounted,
	} from "vue";
	import store from "../services/store";

	export default defineComponent({
		props: {
			token: Boolean,
		},

		emits: ["successfullogin"],

		setup(props, { emit }) {
			const user = reactive({
				first_name: "",
				last_name: "",
				username: "",
				phone: "",
				email: "",
				password: "",
			});

			const success = ref(false);
			const registering = ref(false);
			const customerMessage = ref("");
			const has_error = ref(false);
			const error = ref("");

			const errors = ref({
				phone: [],
				password: [],
			});

			const status = computed(() => store.state.status);

			onMounted(() => {
				document.body.classList.remove("has-aside");
			});

			onUnmounted(() => {
				document.body.classList.add("has-aside");
			});

			function showRegisterForm() {
				registering.value = true;
			}

			function showLoginForm() {
				registering.value = false;
			}

			function loginUser() {
				has_error.value = false;

				store
					.dispatch("login", user)
					.then((res) => {
						if (res.data.status == "error") {
							has_error.value = true;
							error.value = res.data.message;
						} else {
							emit("successfullogin");
						}
					})
					.catch((e) => {
						has_error.value = true;
						error.value = e.message;
					});
			}

			function registerUser() {
				has_error.value = false;

				store
					.dispatch("register", user)
					.then((res) => {
						if (res.data.status == "error") {
							has_error.value = true;
							error.value = res.data.message;
						} else {
							emit("successfullogin");
						}
					})
					.catch((e) => {
						has_error.value = true;
						error.value = e.message;
					});
			}

			return {
				loginUser,
				registerUser,
				customerMessage,
				user,
				has_error,
				error,
				success,
				registering,
				showLoginForm,
				showRegisterForm,
				status,
			};
		},
	});
</script>
